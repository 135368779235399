/*
 *   Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.

 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import React from 'react';
import "./SiteHeader.css";
import { Navbar, Nav, NavItem, MenuItem } from "react-bootstrap";
import { Link } from "react-router-bootstrap"


const SiteHeader = () => {
  return (
    <Navbar fixed="top" expand="lg" className="allNav">
    <Navbar.Brand href='/#'>
            <img src='img/logo.png' className='logo' />
    </Navbar.Brand>
    <Navbar.Collapse className="justify-content-end">
    <Nav >
      <Nav.Item>
          <Nav.Link className="nav-link-color" href="/#paynow" >
                  <div><i className="fa  fa-usd fcolor"></i></div>
                  <div>Online Pay</div>
          </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link className="nav-link-color" href="/#about" >
                  <div><i className="fa fa-info-circle fcolor"></i></div>
                  <div>About</div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link className="nav-link-color" href="/#services" >
                  <div><i className='fa fa-wrench fcolor'></i></div>
                  <div>Services</div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link className="nav-link-color" href="/#faq" >
                  <div><i className='fa  fa-question-circle fcolor'></i></div>
                  <div>FAQ</div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link className="nav-link-color" href="#contact" >
          <div><i className='fa fa-envelope fcolor'></i></div>
          <div>Contact</div>
        </Nav.Link>
      </Nav.Item>
    </Nav>
    </Navbar.Collapse>
  </Navbar>
  );
};

export default SiteHeader;
