const dev = {
  dynamodb: {
    REGION: "us-east-1",
    TABLENAME: "georgesoil_consilidated"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_77TbeRIs3",
    APP_CLIENT_ID: "10pet3k8rv6141hac3qfts3ecb",
    IDENTITY_POOL_ID: "us-east-1:21c8b063-e807-4909-baae-0e96c326d373"
  }
};

const prod = {
  dynamodb: {
    REGION: "us-east-1",
    TABLENAME: "georgesoil_consolidated"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_77TbeRIs3",
    APP_CLIENT_ID: "10pet3k8rv6141hac3qfts3ecb",
    IDENTITY_POOL_ID: "us-east-1:21c8b063-e807-4909-baae-0e96c326d373"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
