import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home";
import Login from "./containers/Login";
import Modify from "./containers/Modify";
/*
import NotFound from "./containers/NotFound";
*/

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/home" exact component={Home} appProps={appProps} />
      <UnauthenticatedRoute path="/" exact component={Login} appProps={appProps} />
      <AuthenticatedRoute path="/modify" exact component={Modify} appProps={appProps} />
    </Switch>
  );
}

/*
      <AuthenticatedRoute path="/modify" exact component={Modify} appProps={appProps} />
      <Route component={NotFound} />
*/
