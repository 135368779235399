/*
 *   Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.

 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import React from 'react';
import "./SiteHeaderAdmin.css";
import { Navbar, Nav, NavItem, MenuItem } from "react-bootstrap";
import { Link } from "react-router-bootstrap"


const SiteHeaderAdmin = () => {
  return (
    <Navbar fixed="top" expand="lg" className="allNav">
    <Navbar.Brand href='/#'>
            <img src='img/logo.png' className='logo' />
    </Navbar.Brand>
  </Navbar>
  );
};

export default SiteHeaderAdmin;
