import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import  $ from "jquery";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import AdminForm from '../components/AdminForm';
import { AWS } from '@aws-amplify/core';
import "./Home.css";
import { Auth } from 'aws-amplify';
import config from '../config';
import SiteHeaderAdmin from "../components/SiteHeaderAdmin"


async function getCreds(){
  return await Auth.currentSession()
}


export default function Home(props) {
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  var user = getCreds();
  console.log(user)
  window.user = Auth;

  AWS.config.update({ region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials(
      {
      IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
      Logins : {
         "cognito-idp.us-east-1.amazonaws.com/${config.cognito.USER_POOL_ID}": user.accessToken
      }

    })
  });
  const dynamodb = new AWS.DynamoDB({apiVersion: '2012-08-10'});

  var session = getCreds();
  console.log(session);

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }

      try {
        //const primaryPrice = await loadPrimaryPrice(dynamodb);
        //console.log(primaryPrice + "WHAT")
        console.log('hi');
        loadPrimaryPrice(dynamodb);
        loadAlert(dynamodb);
        loadServicePrices(dynamodb, 'service_day', '#service_day');
        loadServicePrices(dynamodb, 'service_night', '#service_night');
        loadServicePrices(dynamodb, 'tuneup_nov', '#tuneup_apr');
        loadServicePrices(dynamodb, 'tuneup_sep', '#tuneup_sep');
        loadServicePrices(dynamodb, 'tuneup_nov', '#tuneup_nov');
        loadServicePrices(dynamodb, 'efficiency_tests', '#efficiency_tests');

        //setPrices(prices);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function clickYes() {
    console.log('click yes');
      $('#deliveryBox').fadeIn();
      $('#buttonYes').removeClass('btn-secondary');
      $('#buttonYes').addClass('btn-primary');
      $('#buttonNo').removeClass('btn-primary');
      $('#buttonNo').addClass('btn-secondary');
      $('#message_enabled').val(1);
  }

  function clickNo() {
    console.log('click no');
      $('#deliveryBox').fadeOut();
      $('#buttonNo').removeClass('btn-secondary');
      $('#buttonNo').addClass('btn-primary');
      $('#buttonYes').removeClass('btn-primary');
      $('#buttonYes').addClass('btn-secondary');
      $('#message_enabled').val(0);
  }

  function loadServicePrices(dynamodb, type, id){
    var params2 = {
      TableName: 'georgesoil_consolidated',
      ExpressionAttributeNames: {
        "#D": "date"
      },
      ExpressionAttributeValues: {
        ":type": { S: "SERVICE"},
        ":date": { S: type }
      },
      KeyConditionExpression: "dataType = :type AND #D = :date",
    }

    // load last price from dynamo
    dynamodb.query(params2, function(err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else{
        var latest_price = data.Items[0].price.S
        $(id).val(latest_price);
      }
    });
  }

  function loadPrimaryPrice(dynamodb){
    var params2 = {
      TableName: 'georgesoil_consolidated',
      KeyConditionExpression: "dataType = :type",
      ExpressionAttributeValues: {
        ":type": { S: "PRICE"}
      },
      ScanIndexForward: false,
      Limit: 1
    }

    // load last price from dynamo
    dynamodb.query(params2, function(err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else{
        var latest_price = data.Items[0].price.S
        console.log("Latest Price: " + latest_price);
        $('#price').val(latest_price);
        return latest_price
      }
    });
  }

  function loadAlert(dynamodb) {
    var params = {
      TableName: 'georgesoil_consolidated',
      KeyConditionExpression: "dataType = :type",
      ExpressionAttributeValues: {
        ":type": { S: "ALERT"}
      }
    }

    dynamodb.query(params, function(err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else{
        var alert_message_thing = data.Items[0].message.S
        console.log("ALERT: " + alert_message_thing)
        $('#alert_message').val(alert_message_thing);
        if (data.Items[0].alert_enabled.S == '1') {
          $('#alert_enabled').val(1);
          clickYes();
        } else {
          $('#alert_enabled').val(0);
          clickNo();
        }
      }
    });
  }



  function renderNotAuth() {
    return (
      <div className="lander">
        <h1>Unauthenticated!</h1>
      </div>
    );
  }

  function renderAuth() {
    return (
      <div className="lander">
        <AdminForm />
      </div>
    );
  }

  return (
    <div className="full-width">
      <SiteHeaderAdmin />
      <br/><br/><br/><br/><br/><br/>
      <div className="Home">
        {props.isAuthenticated ? renderAuth() : renderNotAuth()}
      </div>
    </div>
  );
}
