import React from 'react';
import "./SiteFooter.css";

const SiteFooter = () => {
  return (
<footer className="footer">
        <div className="footer-full">
            PO Box 70. Franklin, MA 02038. <span className='nowrap' ><a href="tel:5088588544">(508) 858 8544</a>. USA</span>
        </div>
    </footer>
  );
};

export default SiteFooter;
