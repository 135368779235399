import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { LinkContainer } from "react-router-bootstrap";
import $ from 'jquery';
//import  NavbarCustom  from "./NavbarCustom"
import SiteHeader from '../components/SiteHeader';
import SiteFooter from '../components/SiteFooter';
import Slider from '../components/Slider'
import "./Home.css";
//import  { Datejs } from 'datejs';
//import { datetimepicker } from 'tempusdominus-bootstrap-4';
import { Datetime } from 'react-datetime';
import AWS from 'aws-sdk';
import config from '../config';
import { Auth } from 'aws-amplify';


//import "./full-slider.css"


const HomeSection = () => (
    <div id='home'>
        <Slider />
    </div>
);


const PaynowSection = () => (
    <section id='paynow' >
        <div className='container' className='center'>
            <ol className='list-group'>
                <li className='list-group-item serviceTitle bounding-box'><i className='fa fa-dollar serviceIcon'></i>Online Payment</li>
                <li className='list-group-item left bounding-box font14'>

                    <div><b>Pay Online Now - It's Easy!</b></div>
                    <div className='div-spacing-10'>Just follow these four steps:</div>
                    <div className='stepButton'>1. Click the Pay Now Button</div>
                    <div className='stepButton'>2. Look at your invoice to determine what you owe</div>
                    <div className='stepButton'>3. Enter the amount you owe</div>
                    <div className='stepButton'>4. Fill in your information and submit the payment</div>
                    <div className='div-spacing-top-30'>That's it! You'll receive a receipt to your email address upon payment</div>
                    <div className='div-spacing-top-10 center width-100'>
                        <div><button disabled className='hosted-pay-button'>Pay Now</button>
                            <script src="https://www.convergepay.com/hosted-payments/buy_button_script/546a2f6574794178544e2b33564e4349556f41576a51414141574e51636b3747"></script>
                        </div>
                        <i className='fa fa-cc-visa'></i>
                        <i className='fa fa-cc-mastercard'></i>
                        <i className='fa fa-cc-discover'></i>
                        <i className='fa fa-cc-amex'></i>
                    </div>
                    <br/>
                </li>
            </ol>
        </div>
    </section>
);

const AboutSection = () => (
    <section id='about'>
    <div className='container'>
        <div className='center'>
            <h3>About</h3>
            <div><img src='img/george.jpg' className='img-rounded george-image' /></div>
            <div className='about-div'>
                George's Oil was started in 2012 by myself George Bullukian. I have over <i>45 years experience</i> in the oil heating business as a former owner, deliveryman, and serviceman. My company was always known for low pricing, quality service,
                and honesty. My reputation for these qualities is well known to my former customers.<br/><br/> I started working for my father Harry Bullukian in the mid 60's and have been in the business every since. In 2011, I sold my old business
                and thought I would try some other ventures, but the oil business was still in my blood and I missed it, thus a new business was started. Even though the business is new, I am not new to the business. <br/><br/> In Bristol, Worcester,
                and Norfolk Counties I offer low prices, quality service, and a small oil company approach where the customer comes first. When you call my company I will always answer the phone, no secretary or answering service. So if there ever
                is a problem, you will talk to the owner to resolve any issues or problems (of course please don't call in the middle of the night for non-emergency questions).<br/><br/>
            </div>
        </div>
    </div>
</section>
)

const ServicesSection = () => (
<section id='services'>
        <div className='container'>
            <ul className='list-group'>
                <li className='list-group-item serviceTitle'><i className='fa fa-wrench serviceIcon'></i>Services</li>
                <li className='list-group-item'>Call in or Automatic Oil Delivery</li>
                <li className='list-group-item'>Emergency Service</li>
                <li className='list-group-item'>Boiler, Burner, Furnace Sales & Installation</li>
                <li className='list-group-item'>Annual Tune-Ups</li>
                <li className='list-group-item'>Efficiency Tests</li>
            </ul>
            <br/><br/>
            <ul className='list-group'>
                <li className='list-group-item serviceTitle'><i className='fa fa-smile-o serviceIcon'></i>Benefits</li>
                <li className='list-group-item'>You always speak with the owner</li>
                <li className='list-group-item'>Low prices</li>
                <li className='list-group-item'>We accept cash, check, or credit cards</li>
                <li className='list-group-item'>We accept Self Help and Citizens for Citizens customers</li>
            </ul>
            <br/><br/>
            <ul className='list-group'>
                <li className='list-group-item serviceTitle'><i className='fa fa-money serviceIcon'></i>Rates</li>
                <li className='list-group-item'><b>Service <span className='font-small'>(M-F 8am-4pm)</span>:</b> <span id='service_day'>--</span></li>
                <li className='list-group-item'><b>Service <span className='font-small'>(All Other times)</span>:</b> <span id='service_night'>--</span></li>
                <li className='list-group-item'><b>Annual Tune Up <span className='font-small'>(Apr 1 - Aug 31)</span>:</b> <span id='tuneup_apr'>--</span></li>
                <li className='list-group-item'><b>Annual Tune Up <span className='font-small'>(Sep 1 - Oct 31)</span>:</b> <span id='tuneup_sep'>--</span></li>
                <li className='list-group-item'><b>Annual Tune Up <span className='font-small'>(Nov 1 - Mar 30)</span>:</b> <span id='tuneup_nov'>--</span></li>
                <li className='list-group-item'><b>Efficiency Tests:</b> <span id='efficiency_tests'>--</span></li>
            </ul>
            <div className='email-us'>
                * Email us for a full list of details and exemptions
            </div>
            <div id='serviceHistory'><i className='fa fa-spin fa-refresh'></i> Loading Price History</div>
            <br/><br/>
        </div>
    </section>
);

const FaqSection = () => (
    <section id='faq'>
    <div className='container'>
        <h3>FAQ</h3>
        <div>
            <div className='faqQ'>What methods of payment do you accept?</div>
            <div className='faqAnswer'>
                Cash, checks and Credit Cards(Master Card, Visa, American Express, and Discover)
            </div>
            <br/>
            <div className='faqQ'>When is payment expected?</div>
            <div className='faqAnswer'>
                All payments are due when services are rendered unless you have been extended credit. Credit customers have 10 days to pay. By doing it this way prices can be kept low as our billing costs and expenses are kept low.
            </div>
            <br/>
            <div className='faqQ'>Do you accept self help or citizens for citizens customers?</div>
            <div className='faqAnswer'>
                Yes.
            </div>
            <br/>
            <div className='faqQ'>What towns do you serve?</div>
            <div className='faqAnswer'>
                Towns in Bristol, Worcester, and Norfolk Counties.
                <br/><br/>
                <ul className='townsList'>
                    <li>Attleboro</li>
                    <li>Bellingham</li>
                    <li>Foxboro</li>
                    <li>Franklin</li>
                    <li>Hopedale</li>
                    <li>Medway</li>
                    <li>Mendon</li>
                    <li>Milford</li>
                    <li>Millis</li>
                    <li>Norfolk</li>
                    <li>North Attleboro</li>
                    <li>North Dighton</li>
                    <li>Norton</li>
                    <li>Plainville</li>
                    <li>Rehobeth</li>
                    <li>Seekonk</li>
                    <li>Sheldonville</li>
                    <li>Taunton</li>
                    <li>Wrentham</li>
                </ul>
            </div>
            <br/>
            <div className='faqQ'>How much notice is needed to receive an oil delivery or service?</div>
            <div className='faqAnswer'>
                Oil deliveries are usually delivered within three business days.
            </div>
            <br/>
            <div className='faqQ'>Why do you need our email address?</div>
            <div className='faqAnswer'>
                In todays world with increasing postal costs it is easier and cheaper to email you any important info or specials we are offering. Also in the future if you are extended credit bills will be mailed by email to save cost. If you do not have an email account
                we will mail all invoices and news.
            </div>
            <br/>
            <div className='faqQ'>Does this website have a privacy policy?</div>
            <div className='faqAnswer'>
                <div>We do - <a href='policy'>Privacy Policy</a></div>
            </div>
        </div>
    </div>
</section>
);

const ContactSection = () => (
    <section id='contact'>
    <div className='container'>
        <h3>Contact Us</h3>
            <div>For delivery, service, or just to ask a question</div><br/>
            <form id="theContactForm" action="javascript:sendEmail();" method="POST">
                <div className="form-group">
                    <label for="full_name">Full Name</label>
                    <input type="text" className="form-control" id="full_name" name="full_name" placeholder="Full Name" />
                </div>
                <div className="form-group">
                    <label for="email">Email</label>
                    <input type="email" className="form-control" id="email" name="email" placeholder="Email Address" />
                </div>
                <div className="form-group">
                    <label for="address">Address</label>
                    <input type="text" className="form-control" id="address" name="address" placeholder="Home Address" />
                </div>
                <div className="form-group">
                    <label for="city">City</label>
                    <select className='form-control' id='city' name='city'>
        <option value="" disabled selected>Select Your City</option>
        <option value='Attleboro'>Attleboro</option>
        <option value='Bellingham'>Bellingham</option>
        <option value='Foxboro'>Foxboro</option>
        <option value='Franklin'>Franklin</option>
        <option value='Hopedale'>Hopedale</option>
        <option value='Medway'>Medway</option>
        <option value='Mendon'>Mendon</option>
        <option value='Milford'>Milford</option>
        <option value='Millis'>Millis</option>
        <option value='Norfolk'>Norfolk</option>
        <option value='North Attleboro'>North Attleboro</option>
        <option value='North Dighton'>North Dighton</option>
        <option value='Norton'>Norton</option>
        <option value='Plainville'>Plainville</option>
        <option value='Rehobeth'>Rehobeth</option>
        <option value='Seekonk'>Seekonk</option>
        <option value='Sheldonville'>Sheldonville</option>
        <option value='Taunton'>Taunton</option>
        <option value='Wrentham'>Wrentham</option>
      </select>
                </div>
                <div className="form-group">
                    <label for="zipcode">Zipcode</label>
                    <input type="text" className="form-control" id="zipcode" name="zipcode" placeholder="Zipcode" />
                </div>
                <div className="form-group">
                    <label for="phone">Phone</label>
                    <input type="phone" className="form-control" id="phone" name="phone" placeholder="Phone Number" />
                </div>
                <div className="form-group">
                    <label for="contactType">Perfered Contact Method</label>
                    <select className='form-control' id='contactType' name='contactType' >
        <option value='phone'>Phone</option>
        <option value='email'>Email</option>
        <option value='text'>Text Message</option>
      </select>
                </div>
                <div className="form-group">
                    <b>Delivery?</b>
                </div>
                <div className="form-group">
                    <button type='button' className='btn btn-secondary' id='buttonYes' onClick={clickYes()}>Yes</button>
                    <button type='button' className='btn btn-primary btn-disabled' id='buttonNo' onClick={clickNo()}>No</button>
                </div>
                <div id='deliveryBox'>
                    <div className="form-group">
                        <label for="gallons">Gallons (optional)</label>
                        <input type="number" className="form-control" id="gallons" name="gallons" placeholder="Gallons" />
                    </div>
                    <div className="form-group">
                        <label for="tank">Facing your house, where is the oil fill?</label>
                        <input type="text" className="form-control" id="tank" name="tank" placeholder="Tank Location" />
                    </div>
                    <div className="form-group">
                        <label for="datepicker1">Perfered Date & Time</label>
                        <div className='relative'>
                            <input type="text" className="form-control datetimepicker-input" id="datetimepicker1" data-toggle="datetimepicker" data-target="#datetimepicker1" />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label for="message">Message</label>
                    <textarea id='message' name='message' className='form-control'></textarea>
                </div>
                <div id='afterMessage'>&nbsp;</div>
                <div className='form-group'>
                    <button id='contactUsBut' className='btn btn-primary'>Submit</button>
                </div>
                <div id='processing' >Proccessing Please Wait...</div>
                <input type='hidden' id='delivery' value='No' />
    </form>
    </div>
    <br/><br/><br/><br/>
</section>
);

const AlertSection = () => (
    <div id='allAlert'>
        <div id='darkside'>&nbsp;</div>
        <div className='width-100'>
            <div id='news'>
                <div id='titleBar'>Attention!</div>
                <div id='newstext'>
                    <div id='alert_message'></div>
                    <br/>
                    <div id='emailme'>Contact <a href='mailto:george@georgesoil.com'>George</a> for more details.</div>
                    <br/>
                </div>
                <div id='closeBut'>
                    <button className='btn btn-primary' type='button' onclick="closeAlert();" >Close</button>
                </div>
            </div>
        </div>
    </div>
);

const PriceGage = () => (
    <div className='priceGage'>
     Current Price: <span id='price'>-.--</span>/gal
    </div>
);

$(document).ready(function() {
    window.onresize = function() {
        $('.priceGage').css('top', $('nav').height() + 15);
    }

    $('.priceGage').css('top', $('nav').height() + 15);
    $('.priceGage').fadeIn();

    /*
    $(document).click(function() {
        $('#navbarResponsive').collapse('hide');
    });
    */

    $('#contactUsBut').click(function() {
        $('#contactUsBut').hide();
        $('#processing').fadeIn();
        //sendEmail();
        return false;
    });

    $('#city').change(function() {
        var zip_map = {};
        zip_map["Attleboro"] = "02703";
        zip_map["Bellingham"] = "02019";
        zip_map["Foxboro"] = "02035";
        zip_map["Franklin"] = "02038";
        zip_map["Hopedale"] = "01747";
        zip_map["Medway"] = "02053";
        zip_map["Mendon"] = "38356";
        zip_map["Milford"] = "01757";
        zip_map["Millis"] = "02054";
        zip_map["Norfolk"] = "02056";
        zip_map["North Attleboro"] = "02760";
        zip_map["North Dighton"] = "02764";
        zip_map["Norton"] = "02766";
        zip_map["Plainville"] = "02762";
        zip_map["Rehobeth"] = "02769";
        zip_map["Seekonk"] = "02771";
        zip_map["Sheldonville"] = "02070";
        zip_map["Taunton"] = "02780";
        zip_map["Wrentham"] = "02093";

        var city = $('#city').val();
        $('#zipcode').val(zip_map[city]);
    });

    /*
    var start_date = Datejs.today().add(2).days().add(9).hours()
    $(function() {
        $('#datetimepicker1').datetimepicker({
            format: 'MM/DD/YYYY hh:mm A',
            minDate: start_date,
            disabledHours: [0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23, 24],
            sideBySide: true,
            stepping: 30
        });
    });

    handleRotation();
    getCurrentPrice();
    getPriceHistory();
    */
    try {
        var message = '';
        //var message = getUrlVars()["payment"];
        if (message == 'success') {
          console.log('thank you');
            //thankYou();
        } else {
            console.log('get alert');
            //getAlert();
        }
    } catch (error) {
        console.log('get alert');
        //getAlert();
    }
    //$(document).scroll(function(){
    //  closeAlert();
    //});

    //setTimeout("$(document).scrollTop(0)", 400);
});

function clickYes() {
    $('#deliveryBox').fadeIn();
    $('#buttonYes').removeClass('btn-secondary');
    $('#buttonYes').addClass('btn-primary');
    $('#buttonNo').removeClass('btn-primary');
    $('#buttonNo').addClass('btn-secondary');
    $('#delivery').val('Yes');
}

function clickNo() {
    $('#deliveryBox').fadeOut();
    $('#buttonNo').removeClass('btn-secondary');
    $('#buttonNo').addClass('btn-primary');
    $('#buttonYes').removeClass('btn-primary');
    $('#buttonYes').addClass('btn-secondary');
    $('#delivery').val('No');
}

async function getCreds(){
  return await Auth.currentSession()
}

var user = getCreds();

AWS.config.update({ region: 'us-east-1',
  credentials: new AWS.CognitoIdentityCredentials(
    {
    IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
    Logins : {
       "cognito-idp.us-east-1.amazonaws.com/${config.cognito.USER_POOL_ID}": user.accessToken
    }

  })
});

const dynamodb = new AWS.DynamoDB({apiVersion: '2012-08-10'});


/*** CAME FROM OTHER COPY JOB ***/
function loadServicePrices(dynamodb, type, id){
  var params2 = {
    TableName: 'georgesoil_consolidated',
    ExpressionAttributeNames: {
      "#D": "date"
    },
    ExpressionAttributeValues: {
      ":type": { S: "SERVICE"},
      ":date": { S: type }
    },
    KeyConditionExpression: "dataType = :type AND #D = :date",
  }

  // load last price from dynamo
  dynamodb.query(params2, function(err, data) {
    if (err) console.log(err, err.stack); // an error occurred
    else{
      var latest_price = data.Items[0].price.S
      $(id).val(latest_price);
    }
  });
}

function loadPrimaryPrice(dynamodb){
  var params2 = {
    TableName: 'georgesoil_consolidated',
    KeyConditionExpression: "dataType = :type",
    ExpressionAttributeValues: {
      ":type": { S: "PRICE"}
    },
    ScanIndexForward: false,
    Limit: 1
  }

  // load last price from dynamo
  dynamodb.query(params2, function(err, data) {
    if (err) console.log(err, err.stack); // an error occurred
    else{
      var latest_price = data.Items[0].price.S
      console.log("Latest Price: " + latest_price);
      $('#price').val(latest_price);
      return latest_price
    }
  });
}

function loadAlert(dynamodb) {
  var params = {
    TableName: 'georgesoil_consolidated',
    KeyConditionExpression: "dataType = :type",
    ExpressionAttributeValues: {
      ":type": { S: "ALERT"}
    }
  }

  dynamodb.query(params, function(err, data) {
    if (err) console.log(err, err.stack); // an error occurred
    else{
      var alert_message_thing = data.Items[0].message.S
      console.log("ALERT: " + alert_message_thing)
      $('#alert_message').val(alert_message_thing);
      if (data.Items[0].alert_enabled.S == '1') {
        $('#alert_enabled').val(1);
        clickYes();
      } else {
        $('#alert_enabled').val(0);
        clickNo();
      }
    }
  });
}
/*** ***/


const Home = () => {
  useEffect(() => {
    async function onLoad() {
        //const primaryPrice = await loadPrimaryPrice(dynamodb);
        //console.log(primaryPrice + "WHAT")
        console.log("RUNNING")
        loadPrimaryPrice(dynamodb);
        loadAlert(dynamodb);
        loadServicePrices(dynamodb, 'DAY', '#service_day');
        loadServicePrices(dynamodb, 'NIGHT', '#service_night');
        loadServicePrices(dynamodb, 'TUNE_UP_APR', '#tuneup_apr');
        loadServicePrices(dynamodb, 'TUNE_UP_SEP', '#tuneup_sep');
        loadServicePrices(dynamodb, 'TUNE_UP_NOV', '#tuneup_nov');
        loadServicePrices(dynamodb, 'ET', '#efficency_tests');
    }

    onLoad();
  });

    return (
        <div className="full-width">
            <SiteHeader />
            <div className="turnDeviceNotification">&nbsp;</div>
            <HomeSection />
            <PaynowSection />
            <AboutSection />
            <ServicesSection />
            <FaqSection />
            <ContactSection />
            <AlertSection />
            <PriceGage />
            <div className='priceGage'>
                Current Price: <span id='price'>-.--</span>/gal
            </div>
            <SiteFooter />
        </div>

      )
  }

  export default Home;
