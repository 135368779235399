import React from 'react';
import "./SiteHeader.css";
import { Navbar, Nav, NavItem, MenuItem } from "react-bootstrap";
import { Link } from "react-router-bootstrap"
import $ from 'jquery';
import AWS from 'aws-sdk';
import config from '../config';
import { Auth } from 'aws-amplify';
import './AdminForm.css'


function sendPrice() {
  Auth.currentSession()
  .then(user => {
    console.log(user)


    AWS.config.update({ region: 'us-east-1',
      credentials: new AWS.CognitoIdentityCredentials(
        {

        IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
        Logins : {
           "cognito-idp.us-east-1.amazonaws.com/us-east-1_77TbeRIs3": user.getIdToken().getJwtToken()
        }

      })
    });
    const dynamodb = new AWS.DynamoDB({
      apiVersion: '2012-08-10'
    });

    console.log(dynamodb)

      var price = $('#price').val();
      var token = $('#token').val();
      var date = new Date();
      var date_iso = date.toISOString();
      var date_split = date_iso.split('-')
      var shortdate = date_split[0] + "#" + date_split[1];

    var params_price = {
      TableName: "georgesoil_consolidated",
      Item: {
        'dateType' : { S: 'PRICE'},
        'date' : { S: date_iso }
      }
    }

    try {
      //UPDATES price in all prices
      updateRegularPrice(dynamodb, price, date_iso)

      //UPDATES MAX
      try {
        updateHistoricPrice(dynamodb, price, shortdate,'max', "(:m > #M) OR (attribute_not_exists(#M))")
      } catch{
        console.log('not a max')
      }

      //UPDATES MIN
      try {
        updateHistoricPrice(dynamodb, price, shortdate,'min', "(:m < #M) OR (attribute_not_exists(#M))")
      } catch {
        console.log('not a min')
      }

      $('#price_status_message').html('Price Updated Successfully!');
    } catch {
      console.log('Price FAIL!')
      $('#price_status_message').html('ERROR: Price Update FAILED!');
    }
  })
}

function updateRegularPrice(dynamodb, price, date){
//try catch here?
var params = {
    TableName: 'georgesoil_consolidated',
    Item: {
        "dataType": { "S": "PRICE"},
        "date": { "S": date },
        "price": { "S": price }
    }
}

dynamodb.putItem(params, function(err, data) {
  if (err) console.log(err, err.stack); // an error occurred
  else{
    console.log('Price Reg Updated');
  }
});
}

//FUNCTION updating the alerts
function sendAlert() {

  Auth.currentSession()
  .then(user => {
    console.log(user)


    AWS.config.update({ region: 'us-east-1',
      credentials: new AWS.CognitoIdentityCredentials(
        {

        IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
        Logins : {
           "cognito-idp.us-east-1.amazonaws.com/us-east-1_77TbeRIs3": user.getIdToken().getJwtToken()
        }

      })
    });
    const dynamodb = new AWS.DynamoDB({
      apiVersion: '2012-08-10'
    });

  var message = $('#alert_message').val();
  var message_enabled = $('#message_enabled').val();
  //try catch here?
  var params = {
      TableName: 'georgesoil_consolidated',
      ExpressionAttributeNames: {
          "#M": "message",
          "#ME": "alert_enabled"
        },
      ExpressionAttributeValues: {
          ":m": {
            "S": message
          },
          ":me": {
            "S": message_enabled
          }
      },
      Key: {
          "dataType": {
            "S": "ALERT"
          },
          "date": {
            "S": "CURRENT"
          }
      },
      UpdateExpression: "SET #M = :m, #ME = :me",
  }

  dynamodb.updateItem(params, function(err, data) {
    if (err){
      console.log(err, err.stack); // an error occurred
      $('#alert_status_message').html('ERROR updating Alert');

    } else{
      console.log('Alert Updated');
      $('#alert_status_message').html('Success updating Alert');
      }
    });
  });
}

function updateServicePrices(){
  Auth.currentSession()
  .then(user => {
    console.log(user)


    AWS.config.update({ region: 'us-east-1',
      credentials: new AWS.CognitoIdentityCredentials(
        {

        IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
        Logins : {
           "cognito-idp.us-east-1.amazonaws.com/us-east-1_77TbeRIs3": user.getIdToken().getJwtToken()
        }

      })
    });
    const dynamodb = new AWS.DynamoDB({
      apiVersion: '2012-08-10'
    });

  var service_day = $('#service_day').val();
  var service_night = $('#service_night').val();
  var tuneup_apr = $('#tuneup_apr').val();
  var tuneup_sep = $('#tuneup_sep').val();
  var tuneup_nov = $('#tuneup_nov').val();
  var efficiency_tests = $('#efficiency_tests').val();

  //try catch here?
  service_price_updater(dynamodb, 'service_day', service_day);
  service_price_updater(dynamodb, 'service_night', service_night);
  service_price_updater(dynamodb, 'tuneup_apr', tuneup_apr);
  service_price_updater(dynamodb, 'tuneup_sep', tuneup_sep);
  service_price_updater(dynamodb, 'tuneup_nov', tuneup_nov);
  service_price_updater(dynamodb, 'efficiency_tests', efficiency_tests);


  });
}

function service_price_updater(dynamodb, type, data){
  var params = {
      TableName: 'georgesoil_consolidated',
      ExpressionAttributeNames: {
          "#M": "price"
        },
      ExpressionAttributeValues: {
          ":m": {
            "S": data
          }
      },
      Key: {
          "dataType": {
            "S": "SERVICE"
          },
          "date": {
            "S": type
          }
      },
      UpdateExpression: "SET #M = :m"
  }
  dynamodb.updateItem(params, function(err, data) {
    if (err){
      console.log(err, err.stack); // an error occurred
      $('#service_price_status_message').html('ERROR updating service prices');

    } else{
      console.log('Alert Updated');
      $('#service_price_status_message').html('SUCCESS updating service prices');
      }
    });
}

function clickYes() {
  console.log('click yes');
    $('#deliveryBox').fadeIn();
    $('#buttonYes').removeClass('btn-secondary');
    $('#buttonYes').addClass('btn-primary');
    $('#buttonNo').removeClass('btn-primary');
    $('#buttonNo').addClass('btn-secondary');
    $('#message_enabled').val(1);
}

function clickNo() {
  console.log('click no');

    $('#deliveryBox').fadeOut();
    $('#buttonNo').removeClass('btn-secondary');
    $('#buttonNo').addClass('btn-primary');
    $('#buttonYes').removeClass('btn-primary');
    $('#buttonYes').addClass('btn-secondary');
    $('#message_enabled').val(0);
}

function updateHistoricPrice(dynamodb, price, shortdate, type, condition){
//try catch here?
var params = {
    TableName: 'georgesoil_consolidated',
    ExpressionAttributeNames: {
        "#M": type
      },
    ExpressionAttributeValues: {
        ":m": {
          "S": price
        }
    },
    Key: {
        "dataType": {
          "S": "HISTORY"
        },
        "date": {
          "S": shortdate
        }
    },
    UpdateExpression: "SET #M = :m",
    ConditionExpression: condition
}

dynamodb.updateItem(params, function(err, data) {
  if (err){
    //console.log(err, err.stack); // an error occurred
    console.log('Not a ' + type );

  } else{
    console.log("Updated historic table for " + type + " <")
  }
});
}


const AdminForm = () => {
  return (
    <div className='container center'>
      <div className='form-group'>
        <label for="price"><b>Insert New Price</b></label>
        <input className='form-control price-input' type='text' id='price' name='price' />
      </div>
      <div className='form-group'>
        <button id='updatePrice' onClick={sendPrice} className='btn btn-primary' >Update Price</button>
      </div>
      <div id='price_status_message'> </div>

      <hr />
      <div className='form-group'>
        <label for="servicePrices"><b>Service Day (M-F 8am-4pm)</b></label>
        <input className='form-control service-day' type='text' id='service_day' name='service_day' />
        <label for="servicePrices"><b>Service Night (All other Times)</b></label>
        <input className='form-control service-night' type='text' id='service_night' name='service_night' />
        <label for="servicePrices"><b>Tune Up (April 1 - Aug 31)</b></label>
        <input className='form-control tuneup_apr' type='text' id='tuneup_apr' name='tuneup_apr' />
        <label for="servicePrices"><b>Tune Up (Sep 1 - Oct 31)</b></label>
        <input className='form-control tuneup_sep' type='text' id='tuneup_sep' name='tuneup_sep' />
        <label for="servicePrices"><b>Tune Up (Nov1 - Mar 30)</b></label>
        <input className='form-control tuneup_nov' type='text' id='tuneup_nov' name='tuneup_nov' />
        <label for="servicePrices"><b>Efficiency Tests</b></label>
        <input className='form-control efficiency_tests' type='text' id='efficiency_tests' name='efficiency_tests' />
      </div>
      <div className='form-group'>
        <button id='updatePrice' onClick={updateServicePrices} className='btn btn-primary' >Update Service Prices</button>
      </div>
      <div id='service_price_status_message'> </div>
      <hr />

      <div className='alert-style' ></div>
      <div className="form-group">
        <b>Alert Status</b>
      </div>
      <div className='form-group'>
        <div className="form-group">
          <button type='button' className='btn btn-secondary' id='buttonYes' onClick={clickYes}>On</button>
          <button type='button' className='btn btn-primary btn-disabled' id='buttonNo' onClick={clickNo} >Off</button>
        </div>
      </div>

      <div id='deliveryBox' >
        <div className='form-group'>
          <label for='alert message'>Alert Message</label>
          <textarea className='form-control' id='alert_message'></textarea>
        </div>
      </div>
      <div className='form-group'>
        <button id='updateAlert' className='btn btn-primary' onClick={sendAlert}>Update Alert</button>
      </div>
      <div id='alert_status_message'> </div>
      <input type='hidden' value='unknown' id='message_enabled' />

      </div>
);
};

export default AdminForm;
