import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './slider.css';

const Slider = () => {
  return (
    <Carousel className="SliderControl full-width">
        <Carousel.Item className='slider1'> </Carousel.Item>
        <Carousel.Item className='slider2'> </Carousel.Item>
        <Carousel.Item className='slider3'> </Carousel.Item>
    </Carousel>

    );
};

export default Slider;
